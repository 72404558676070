.container {
    background-color: rgb(250, 250, 250);
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
}

.loader {
    margin: auto;
}

.logo {
	display: block;
	width: 100%;
	max-width: 440px;
}