.canvas {
    width: 100%;
    height: 100%;
    cursor: url('../images/cursor_pencil.png') 0 31, move;
    touch-action: none;
}

.canvasWrapper {
	flex: 1 1 0;
	overflow: hidden;
	width: 100%;
}
