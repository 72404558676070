.modal {
  width: 80vw;
  height: 60vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  z-index: 1001;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1000;
}

.video {
  width: 100%;
  position: relative;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
    background: #f6cb23;
    width: 60px;
    height: 60px;
    cursor: pointer;
}

.close:after {
    content: '';
    height: 60px;
    border-left: 2px solid #fff;
    position: absolute;
    transform: rotate(45deg);
    left: 28px;
}

.close:before {
    content: '';
    height: 60px;
    border-left: 2px solid #fff;
    position: absolute;
    transform: rotate(-45deg);
    left: 28px;
}

.title {
  margin: 0px;
  padding: 24px 24px 24px;
  color: rgb(66,66,66);
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
}

.body {
  font-size: 16px;
  color: rgba(66,66,66,.6);
  padding: 0px 24px 24px;
  box-sizing: border-box;
  overflow-y: hidden;
  border-top: none;
  border-bottom: none;
  max-height: 423px;
}

.footer {
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  text-align: right;
  margin-top: 0px;
}