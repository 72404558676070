.bgimg {
  width: 100%;
  border-radius: 0.5em;
  border: 7px solid white;
}

* {
  box-sizing: border-box;
}

.themeContainer {
  flex: 1 1 0px;
  padding-top: 2vh;
  text-align: center;
  padding-bottom: 5vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #3f51b5;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  font-family: Roboto, sans-serif;
}

/* Create three equal columns that sits next to each other */
.previewWrapper {
  width: 100%;
  max-width: 95vh;
  display: inline-block;
  padding: 15px;
  opacity: 0;
  animation-name: bounceIn;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: .5s;
}

@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}