.pencils {
  padding: 4px;
  width: 5vw;
  max-width: 60px;
  height: auto;
  z-index: 999;
  overflow: hidden;
  transition:all 1s ease;
  cursor: pointer;
}

.styleActive { 
	transform: translateY(2vh);
}

.styleInactive {
	transform: translateY(7.5vh);
}

@media screen and (max-width: 850px) {
	.pencils {
		width: 7vw;
	}
}