.logoWrapper {
	text-align: center;
}

.mergedLogo {
	width: 100%;
	max-width: 600px;
}

.contributorsContainer {
	display: flex;
}

.columnWrapper {
	display: flex;
	flex-direction: row;
	margin: auto;
	white-space: nowrap;
	margin-bottom: 20px;
}

.leftColumn {
	flex: 1 1 0px;
	margin-right: 20px;
}

.rightColumn {
	flex: 1 1 0px;
}