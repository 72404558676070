.students {
    text-align: center;
    font-family: Roboto, sans-serif;
    width: 100%;
    margin-bottom: 5vh;
    table-layout: fixed;
}
  
.students td, .students th {
    padding: 8px;
    color: #3c4043;
}

.students tr:nth-child(even){background-color: #ddd;}
  
.students th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    font-size: 20px;
}

.settingsimg {
  width: 50px;
  height: 50px;
  padding: 10px;
}

.noStudentsMessage {
  text-align: center;
}

.disabledLink {
  pointer-events: none;
}
 
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  font-family: Roboto, sans-serif;
}

.headerContainer {
  height: 12rem;
  min-height: 12rem;
  margin: 20px auto;
  border-radius: 10px;
  position: relative;
}

.settingsButton {
  position: absolute;
  left: 10px;
  top: 10px;
}

.studentLinkContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: right;
}

.codeContainer {
  position: absolute;
  right: 20px;
  bottom: 10px;
  text-align: right;
}

.joinIcon {
  cursor: pointer;
}

.classTitle {
  position: absolute;
  left: 20px;
  bottom: 10px;
  font-size: 2.5rem;
}

.classCode {
  font-size: 1.5rem;
}

.codeLabel {
  font-size: 1rem;
  margin-bottom: -5px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold
}

.studentLinkLabel {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold
}

.contentWrapper {
  width: calc(100% - 2*1.5rem);
  max-width: 52.5rem;
  margin: 0px auto;
  flex: 1 1 0px;
}

.scrollContainer {
  overflow: scroll;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
}

.classroomButtonContainerStyle {
  vertical-align: middle;
  margin-left: 10px;
  display: inline-block;
  height: 30px;
}