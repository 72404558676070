.iconstyle {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
  color: #f6cb23;
  display: inline-block;
  cursor: pointer;
}

.iconstyle2 {
  margin-right: 10px;
}

.linkStyle {
  text-decoration: none;
}