.bgColor {
  background-color: #3f51b5;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  text-align: center;
}

.goodjobImg {
  width: 100%;
  height: auto;
}

.wrapper {
  margin: auto;
  width: 100%;
  max-width: 80vmin;
}

.feedback {
  text-align: center;
}
