.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  font-family: Roboto, sans-serif;
}

.scrollContainer {
  flex: 1 1 0px;
  overflow: scroll;
  margin-bottom: 5vw;
}

.table {
  margin: 5vh auto;
  text-align: center;
  font-family: Roboto, sans-serif;
  table-layout: fixed;
  width: calc(100% - 2*1.5rem);
}

.table td, .students th {
  padding: 8px;
  color: #0D3254;
}

.table tr:nth-child(even){background-color: #ddd;}
  
.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  font-size: 20px;
}

.settingsHeader {
  color: #0D3254;
  font-size: 5vh;
  text-align: center;
  margin: 5vh 0 5vh 0;
}
  
.settingsBody {
    width: 80vw;
    margin: 0 auto;
}
  
.settingRow {
    font-size: 1vh;
}

.settingValue {
  font-size: 15px;
  padding-top: 5px;
}

.pixelButton {
  font-size: 15px;
  padding-top: 5px;
  transition-duration: 0.4s;
  cursor: pointer;
}

button[type=submit] {
  border: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #0D3254;
  margin-right: 5px;
  border-radius: 3px;
}

.pixelButton:hover {
  color: black !important;
}

.handButton {
  font-size: 15px;
  padding-top: 5px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.pixelButton {
  font-size: 15px;
  padding-top: 5px;
  transition-duration: 0.4s;
  cursor: pointer;
}

button[type=submit] {
  border: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #0D3254;
  margin-right: 5px;
  border-radius: 3px;
}

.pixelButton:hover {
  color: black !important;
}

.handButton {
  font-size: 15px;
  padding-top: 5px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.trialValue {
  font-size: 15px;
  padding-top: 5px;
}


input[type=checkbox] {
  font-size: 15px;
  margin-right: 3px;
}

.mytoggle {
  padding-left: 14px;
}

.studentSelection {
  font-size: 15px;
  padding: 10px 0px 0px 10px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: calc(40px * 4 + 10px);
  align-content: flex-start;
  margin-bottom: 15px;
  border-radius: 3px;
}

.studentSelection div {
  padding: 4px;
  width: 25%;
  background: lightgray;
  height: 30px;
  margin-bottom: 10px;
  margin-right: 1em;
  border-radius: 3px;
}

  /* Taken from https://www.w3schools.com/howto/howto_css_switch.asp */
  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0px;
  vertical-align: middle;
}

.handednessLabel {
  margin-left: 10px;
  vertical-align: middle;
}
  
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
  
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
  
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}