.logo {
	height: 45px;
	cursor: pointer;
}

.flexFill {
	flex: 1 1 0px;
}

.threeDotsImage {
	height: 30px;
	display: inline-block;
	vertical-align: middle;
}

.popoverContainer {
	width: 300px;
}

.popoverUserInfoContainer {
	text-align: left;
	padding: 20px 10px;
	display: flex;
}

.popoverJoinClassContainer {
	padding: 10px;
	display: flex;
}

.popoverSettingLabel {
	margin: auto 0px;
}

.popoverSettingLabelContainer {
	flex: 1 1 0px;
	display: flex;
}

.popoverSettingContainer {
	padding: 5px 10px 5px 20px;
	display: flex;
}

.popoverFirstSetting {
	margin-top: 10px;
}

.popoverLastSetting {
	margin-bottom: 10px;
}

.className {
	padding: 10px;
	font-weight: bold;
	text-align: center;
}

.appbarAvatarWrapper {
	display: inline-block;
	vertical-align: middle;
}