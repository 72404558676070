.lifeImage {
    float: right;
    width: 50px;
    margin-top: 10px;
    margin-right: 10px;
}

.deadImage {
    float: right;
    width: 50px;
    margin-top: 10px;
    margin-right: 10px;
    opacity: .3;
}