.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
}

.header {
	display: flex;
	flex-direction: row;
	padding: 20px;
}

.logo {
    width: 200px;
    position: absolute;
    right: 10%;
    bottom: 10%;
}

.headerText {
	text-align: center;
	flex: 1 1 0px;
	font-size: 45px;
	font-weight: bold;
	color: rgb(13, 50, 84);
}

.body {
	flex: 1 1 0px;
	overflow: auto;
	position: relative;
	padding: 20px;
	text-align: center;
}

.classCardWrapper {
	padding: 10px;
	width: 100%;
	width: 350px;
	height: 220px;
	display: inline-block;
}

@media screen and (max-width: 750px) {
  .classCardWrapper {
    width: 50%; 
  }
}

@media screen and (max-width: 400px) {
  .classCardWrapper {
    width: 100%; 
  }
}

.classCard {
	position: relative;
	cursor: pointer;
	overflow: hidden;
	height: 100%;
	background: #0D3254;
}

.classCardTitleWrapper {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 68px;
	background: rgba(0,0,0,.4);
	display: flex;
	align-items: center;
}

.classCardTitleContainer {
	flex-grow: 1;
	margin-left: 16px;
	margin-right: 0px;
	color: rgb(255,255,255);
	overflow: hidden;
	text-align: left;
}

.classCardTitleText {
	font-size: 16px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.classCardSubtitleText {
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.footer {
	display: flex;
	padding: 20px;
}

.addClassButton {
	margin: auto;
    border: solid 2px #0D3254;
    border-radius: 6px;
    background-color: #0D3254;
    color: white;
    width: 26vw;
    font-size: 2vw;
    padding: 5px;
}

.messageText {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  color: gray;
}