.background {
  background-color: #3f51b5;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
}

::placeholder {
  color: #0D3254;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #0D3254;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #0D3254;
}

button {
  border: none;
  background-color: Transparent;
  margin: 2px;
}

.signInButton {
  width: 26vw;
}

.orbit {
  content: '';
  height: 70vh; 
  width: 70vw; 
  border: 8px dashed #2a367a;
  border-radius: 60%;
  transform: rotate(9deg);
  display: flex;
  margin: auto;
}

.sun {
  margin: auto;
  text-align: center;
  z-index: 1000;
  transform: rotate(-9deg);
}

.mmlLogo {
  width: 90%;
  max-width: 440px;
  height: auto;
  display: block;
  margin: auto;
  background-color: #3f51b5;
}

.googleSignInButton {
  cursor: pointer;
}

.imageCircle {
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
}

.untImageCircle {
  background-size: 100%;
}

.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 22vmin;
  width: 22vmin;
  border-radius: 50%;
  display: flex;
  z-index: 900;
  font-size: 6vmin;
}

.circleTop {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 22vmin;
  width: 22vmin;
  border-radius: 50%;
  display: flex;
  z-index: 1000;
  font-size: 6vmin;
}

.circle:before {
  position: absolute;
  content: '';
  height: calc(100% + 30px); 
  width: calc(100% + 30px); 
  top: -15px;
  left: -15px;
  border-radius: inherit;
  animation: spin 20s linear infinite;
  background-color: #3f51b5;
}

.orange {
  background-color: #F39439;
}

.orangeRings:before {
  border: 5px dotted #F39439;
}

.green {
  background-color: #4CAF50;
}

.greenRings:before {
  border: 8px dashed #4CAF50;
}

.numCircle {
  height: 15vmin;
  width: 15vmin;
  font-size: 5.5vmin;
}

.textCircle {
  height: 10vmin;
  width: 10vmin;
  font-size: 3vmin;
}

.pink {
 background-color: #D48488;
}

.pinkRings:before {
  border: 5px dotted #D48488;
}

.blue {
  background-color: #02264D;
}

.blueRings:before {
  border: 8px dashed #02264D;
}

.circleText {
  font-family: MML;
  color: white;
  text-align: center;
  margin: auto;
}

@keyframes spin { 
  100% { 
    transform: rotateZ(360deg); 
  }
}

.star {
  width: 100%;
  height: 100%;
  animation: scale 1s infinite;
}

.starContainer {
  position: absolute;
  left: 50%;
  top: 50%;
}

@keyframes scale { 
  0% {
    transform: scale(1.0);
  }
  100% { 
    transform: scale(1.5); 
  }
}