* {
  box-sizing: border-box;
}

html,
body {
  position: fixed;
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  width: 100%;
  height: 100%;
  touch-action: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
img{
  -webkit-user-drag: none;
  -ms-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
}
#canvas-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
#canvas-wrap canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#canvasnavibar {
  position: fixed;
  background-color: #6b5eba;
  height: 70px;
  width: 100%;
}

#homepage {
  color: white;
  display: inline-block;
  margin-top: 30px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 17px;
}

#canvasline {
  border-top: dotted 1px;
  margin-top: 125px;
}

#studentpic {
  display: inline-block;
  margin-top: 15px;
  margin-right: 40px;
}

h6#textItem {
  margin: 0;
}

canvas#writing {
  touch-action: none;
  overflow: hidden;
  display: inline-block;
  /*margin-top: -40px;*/
  width: 100%;
  height: 100%;
}

canvas#map {
  touch-action: none;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 100%;
  /*background-image: url('../images/space.jpeg');*/
}

.column {
  float: left;
  width: 50%;
  padding: 15px 30px;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.row {
  margin: 0 0px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.navigation {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  position: relative;
}

.navigation-ico {
  float: left;
}

.navigation-ico a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navigation-li {
  float: left;
}

.navigation-li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navigation-li a:hover:not(.active) {
  background-color: #111;
}

#nextButton {
  position: fixed;
  top: 210px;
  right: 50%;
  transform: translate(50%, 0);
  font-size: 1.5em;
  padding: 0.5em 1em;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
}
#playButton {
  position: fixed;
  top: 140px;
  left: 155px;
  transform: translate(50%, 0);
  font-size: 1.8em;
  /*padding: 0.5em 1em;
     background: #FFFFFF;
     border: 1px solid #000000;
     border-radius: 5px;*/
}
#vidButton {
  position: fixed;
  top: 140px;
  left: 90px;
  height: 50px;
  width: 50px;
  text-align: center;
  /*border: 1px solid #000000;
    border-radius: 8px;*/
  font-size: 1.8em;
}

.hidden {
  display: none;
}

.active {
  display: initial;
}

#menu {
  position: fixed;
  top: 135px;
  left: 25px;
  height: 50px;
  width: 50px;
  text-align: center;
  /*border: 1px solid #000000;*/
  border-radius: 8px;
  font-size: 1.8em;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.menu.modal.content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  height: 80%;
  overflow-y: scroll;
}

/* Modal Content/Box */
.vid.modal.content {
  background-color: #fefefe;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0);
  padding: 0px;
  /*border: 1px solid #888;*/
  width: auto; /* Could be more or less, depending on screen size */
  height: auto;
  overflow-y: scroll;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
#buttonListA {
  position: fixed;
  top: 80px;
  left: 20%;
}
#buttonListD {
  position: fixed;
  top: 80px;
  left: 30%;
}
#buttonListF {
  position: fixed;
  top: 80px;
  left: 40%;
}
#buttonListK {
  position: fixed;
  top: 80px;
  left: 50%;
}
#buttonListQ {
  position: fixed;
  top: 80px;
  left: 60%;
}
#buttonListS {
  position: fixed;
  top: 80px;
  left: 70%;
}
.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.green {
  background-color: #4caf50;
} /* Green */
.gold {
  background-color: #ffd700;
} /* Gold */
.grey {
  background-color: #333333;
} /* Grey */

#informteacher {
  margin-top: 10%;
}

#congratpic {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.formbackground {
  height: 400px;
  background-color: #e9e2e7;
  border-top: 10px solid #954989;
}

.background {
  text-align: center;
  /*    change to 100% for a full screen style*/
  height: 100%;
  background-size: cover;
  color: black;
  /*background: url("../images/a.jpg") center center no-repeat fixed;*/
}

.row {
  padding: 10% 25% 10% 25%;
}

.form {
  padding-top: 10px;
}

#supportlevel {
  width: 60%;
}

.formElement {
  /*margin: auto;*/
  /*text-align: center;*/
  align-items: center;
  align-content: center;
  display: block !important;
}

#nameForm {
  width: 60%;
  margin: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}